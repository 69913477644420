    /* var ppp = 6; // Post per page
    var pageNumber = 1;

     function load_posts(){

        $('.publication-overlay').css({"opacity": "1", "z-index": 999});
        /* pageNumber++;
        var str = '&pageNumber=' + pageNumber + '&ppp=' + ppp + '&action=more_post_ajax';
        $.ajax({
            type: "POST",
            dataType: "html",
            url: ajax_posts.ajaxurl,
            security: ajax_posts.ajaxnonce,
            data: str,
            success: function(data){

                $('.more_posts_latest').css("display", "none");

                var $data = $(data);

                if($data.length){
                    $(".publication-listing-container").append($data);
                } else{
                    $("#more_posts").hide();
                }

                setTimeout(function(){
                    $('.publication-overlay').css({"opacity": "0", "z-index": -1});
                }, 500);
                return false;
            },

        });
    }

    $("#more_posts").on("click",function() { // When btn is pressed.
        load_posts();
    }); */

    /* jQuery(function($){ // use jQuery code inside this to avoid "$ is not defined" error
        $('.misha_loadmore').click(function(){

            var button = $(this),
                data = {
                    'action': 'loadmore',
                    'query': misha_loadmore_params.posts, // that's how we get params from wp_localize_script() function
                    'page' : misha_loadmore_params.current_page
                };

            $.ajax({ // you can also use $.post here
                url : misha_loadmore_params.ajaxurl, // AJAX handler
                data : data,
                type : 'POST',
                beforeSend : function ( xhr ) {
                    button.text('Loading...'); // change the button text, you can also add a preloader image
                },
                success : function( data ){
                    if( data ) {
                        button.text( 'view more' ); // insert new posts
                        $(".publication-listing-container").append(data);
                        misha_loadmore_params.current_page++;
                        if ( misha_loadmore_params.current_page == misha_loadmore_params.max_page )
                            button.remove(); // if last page, remove the button

                    } else {
                        button.remove(); // if no data, remove the button as well
                    }
                }
            });
        });
    }); */
    jQuery(function($){ // use jQuery code inside this to avoid "$ is not defined" error
        $('.misha_loadmore').click(function(){

            $('.publication-overlay').css({"opacity": "1", "z-index": 999});

            var button = $(this),
                data = {
                    'action': 'loadmore',
                    'query': misha_loadmore_params.posts, // that's how we get params from wp_localize_script() function
                    'page' : misha_loadmore_params.current_page
                };

            $.ajax({ // you can also use $.post here
                url : misha_loadmore_params.ajaxurl, // AJAX handler
                data : data,
                type : 'POST',
                beforeSend : function ( xhr ) {
                    button.text('Loading...'); // change the button text, you can also add a preloader image
                },
                success : function( data ){
                    if( data ) {
                        $('.publication-overlay').css({"opacity": "0", "z-index": -1});
                        button.text( 'view more' ); // insert new posts
                        $('.publication-listing-container').append(data); // insert new posts
                        misha_loadmore_params.current_page++;

                        if ( misha_loadmore_params.current_page == misha_loadmore_params.max_page )
                            button.remove(); // if last page, remove the button

                    } else {
                        button.remove(); // if no data, remove the button as well
                    }
                }
            });
        });
    });

    // $(document).ready(function() {
    //     alert('hello world');
    //     $(document).click(function(event) {
    //         var isClickInsideSearchWidget = $(event.target).closest('.gsc-control-wrapper-cse').length > 0;
    
    //         if (!isClickInsideSearchWidget) {
    //             $('.gsc-control-wrapper-cse').hide();
    //         }
    //     });

    //     $('.gsc-control-wrapper-cse').click(function(event) {
    //         event.stopPropagation();
    //     });
    // });
